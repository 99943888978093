#root {
  /* so the page appear full of body */
  width: 100vw;
  height: 100vh;
  position: relative;
  /* need this position relative otherwise will overflow */
}

.clickable {
  cursor: pointer;
}
.loadScreen{
  display: flex;
  width:100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.alertBox{
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width:75vw;
  height:20vh;
}
.title{
  font-weight:800;
font-size: 18px;
}
.subtitle{
  font-size: 14px;
  margin-top:10px;
}
.retryButton{
  background: #448834 ;
box-shadow: 0px 0px 6px #00000029;
border-radius: 30px;
margin-top:10px;
  color:white;
  display:flex;
  justify-content: center;
  align-items: center;
cursor:pointer;
height: 40px;
width: 50%;
font-weight:800;

}